<template>
  <Popup ref="popupRef" width="500px" title="提交详情" :showConfirm="false" :showCancel="false">
    <div v-if="detailInfo">
      <div class="top-row">
        <LoadImage class="avatar" :src="detailInfo?.reporterLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
        <div>
          <div class="name">{{detailInfo.userName}}</div>
          <div class="time">反馈时间：{{detailInfo.gmtCreate}}</div>
        </div>
      </div>
      <div class="content">{{detailInfo.feedBackContent}}</div>
      <div class="img-list" v-if="feedBackPics && feedBackPics.length > 0">
        <LoadImage class="img" :src="url" v-for="(url, index) in feedBackPics" :key="index" preview></LoadImage>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
export default {
  components: {
    Popup,
    LoadImage
  },
  data() {
    return {
      detailInfo: null,
      feedBackPics: []
    }
  },
  methods: {
    open(option) {
      if(!option.detail) return
      this.detailInfo = option.detail
      if(option.detail.feedBackPics) {
        this.feedBackPics = option.detail.feedBackPics.split(',')
      }
      console.log('feedBackPics', this.feedBackPics)
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
  }
}
</script>

<style lang="scss" scoped>
.top-row {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
  }
  .time {
    font-size: 12px;
    margin-top: 8px;
  }
}
.content {
  font-size: 12px;
  margin: 8px 0;
  line-height: 20px;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img {
    margin: 0 8px 8px 0;
    width: 68px;
    height: 68px;
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>